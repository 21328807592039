import React from 'react';
import Pushdown from '../../components/Pushdown';
import Account from '../../components/svgs/Account';

/**
 * AccountInfoPushdown
 *
 * @param {Object} props
 * @param {string} props.accountNumber - The last 4 account number.
 * @param {string} props.selectedAccountAlias - The nickname of the selected account.
 * @param {Object} props.serviceAddress
 * @param {string} props.serviceAddress.addressLine1
 * @param {string} props.serviceAddress.state
 * @param {string} props.serviceAddress.zip
 */
const AccountInfoPushdown = ({ accountNumber, selectedAccountAlias, serviceAddress }) => {
  const ConditionalTag = selectedAccountAlias ? 'p' : 'strong';

  return (
    <Pushdown icon={<Account />} disableScroll>
      {selectedAccountAlias && (<strong>{selectedAccountAlias}</strong>)}
      {Object.keys(serviceAddress).length ? (
        <ConditionalTag>
          {`${serviceAddress.addressLine1}, ${serviceAddress.state} ${serviceAddress.zip}`}
        </ConditionalTag>
      ) : null}
      {accountNumber && (<p>{`Account Number: ${accountNumber}`}</p>)}
    </Pushdown>
  );
};

export default AccountInfoPushdown;
