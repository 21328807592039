import React from 'react';

const BooleanField = ({
  field = {},
  className = '',
  forceError,
  id,
  value,
  type,
  suppressError,
  form: {
    submitCount,
    errors = {},
    values = {},
    touched = {},
  } = {},
  ...props
}) => {
  const isFormSubmitted = !!submitCount;
  const hasAttemptedInput = !!values[field.name] && touched[field.name];
  const shouldShowError = (
    !suppressError
    && ((isFormSubmitted || hasAttemptedInput) && errors[field.name])
  );
  const shouldShowErrorHighlight = shouldShowError || forceError;
  const errorMessageId = `error_${id}`;
  const { checked, ...fieldProps } = {
    id,
    'aria-invalid': !!shouldShowError,
    'aria-describedby': errorMessageId,
    className: `${className} ${shouldShowErrorHighlight ? 'error' : ''}`,
    type,
    ...field,
    ...props,
  };
  const defaultChecked = (
    (type === 'checkbox' && (checked || values[field.name]))
    || (type === 'radio' && (checked || values[field.name] === value))
  );

  return (
    <>
      <input {...fieldProps} checked={defaultChecked} />
      {shouldShowError && <span id={errorMessageId} className="form-control__error">{errors[field.name]}</span>}
    </>
  );
};

export default BooleanField;
