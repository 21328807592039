import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Loading } from 'adc-ui-components';

import { harnessInit } from 'adc-harness-state';

import PermsError from './Errors/PermsError';
import AppComp from './App';
import GenericError from './Errors/GenericError';
import DefaultLayout from './Layouts/DefaultLayout';

import { getAuth, addRefreshAuthListener } from '../actions/auth';
import { resetUserMessages } from '../actions/userMessages';
import { kibanaLog } from '../helpers/logger';
import ErrorBoundary from '../components/ErrorBoundary';
import XapInterstitial from './XapInterstitial';
import getConfig from '../config';
import { CONSENT } from '../helpers/apis';
import featureFlags, { flagEnabled } from '../helpers/featureFlags';
import FeatureShingles from '../components/FeatureShingles';
import PageApp from './Layouts/PageApp';
import ExtendSessionDialog from '../components/ExtendSessionDialog';

const { paymentSetup: { defaultContinue } } = getConfig();

const RenderApp = (props) => {
  const {
    loading = true,
    permsError,
    isLite,
    redirect,
    error,
    errorData,
    isHarness,
    harnessLoading,
    location,
  } = props;
  if (harnessLoading) { return null; }
  if (error) {
    return (
      <PageApp>
        <DefaultLayout
          component={GenericError}
          isLite={isLite}
          isHarness={isHarness}
          error={errorData}
        />
      </PageApp>
    );
  }

  if (permsError) {
    return (
      <PageApp>
        <DefaultLayout
          component={PermsError}
          isLite={isLite}
          isHarness={isHarness}
          error="perms_error"
        />
      </PageApp>
    );
  }

  if (loading || redirect) {
    return <Loading isHarness={isHarness} />;
  }

  return (
    <ErrorBoundary isLite={isLite} isHarness={isHarness}>
      <FeatureShingles location={location} />
      <AppComp {...props} />
      {!isLite && <XapInterstitial location={location} />}
    </ErrorBoundary>
  );
};

export class Auth extends Component {
  constructor() {
    super();
    this.state = {
      authLoaded: false,
      featureFlagsReady: false,
      referrer: '',
    };

    featureFlags.onReady(() => {
      this.setState({
        featureFlagsReady: true,
      });
    });
  }

  componentDidMount() {
    const { handleHarnessInit, location, handleAddRefreshAuthListener } = this.props;

    const { referrer } = document;
    // In IE11, when the browser history changes the referrer is set to an
    // empty string, so capture it in state on page load before it is lost.
    this.setState({ referrer });

    handleHarnessInit(location);
    handleAddRefreshAuthListener();
  }

  componentDidUpdate(prevProps) {
    const {
      crsId,
      location,
      handleGetAuth,
      device,
      handleResetUserMessages,
      messageShown,
      isLite,
    } = this.props;
    const { referrer, featureFlagsReady } = this.state;
    // Avoid persistent lite session;
    if (
      isLite
      // Empty referrer implies QA activity.
      && referrer
      && !(
        // make sure we're coming from IDM:
        referrer.startsWith('https://idm.xfinity.com/')
        // Allow IDM staging
        || referrer.startsWith('https://idm-st.xfinity.com/')
        // Also, allow redirects within the app.
        || referrer.startsWith(`${window.location.protocol}//${window.location.host}`)
      )
    ) {
      // If not, use `/lite` to clear the lite session, then reload.
      fetch('/lite', {
        // Gonna pass credentials (session data), make sure there aren't any leaks
        mode: 'same-origin',
        credentials: 'same-origin',
        redirect: 'manual', // for here, we just discard the redirect.
      }).then(() => {}, () => {}).then(() => {
        window.location.reload();
      });
    }

    // Consent flow is off, but a crsId is passed in.
    if (featureFlagsReady && !flagEnabled('consent.enabled') && crsId) {
      // Get the continue URL off the consent/resolve service
      fetch(CONSENT.RESOLVE(crsId))
        .then(resp => resp.json())
        // If the response is empty (invalid crsId), fall back to the default
        .then((data = {}) => data.continueUrl || defaultContinue)
        // If any of this fails for any reason (server error, MW version doesn't have
        // service, etc), fall back to the default
        .catch(() => defaultContinue)
        .then((url) => {
          kibanaLog({
            type: 'redirect',
            message: 'crsId present, but consent dark',
            target: url,
            crsId,
          });
          window.location = url;
        });
      return;
    }
    const { authLoaded } = this.state;

    if (!authLoaded && featureFlagsReady) {
      // Don't attempt to getAuth until the feature flags are ready
      // because the getAuth action and reducer are segmented with flags.
      // eslint-disable-next-line
      this.setState({ authLoaded: true });
      handleGetAuth(device);
    }

    if (location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);

      if (messageShown) {
        handleResetUserMessages();
      }
    }
  }

  render() {
    return (
      <div id="page-content" className="page-content">
        <RenderApp {...this.props} />
        <ExtendSessionDialog />
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: {
    crsId,
    loading,
    redirect,
    error,
    errorData,
    permsError,
    macaroon: {
      lite: isLite,
    } = {},
  },
  harness: { loading: harnessLoading, device, isHarness },
  userMessages: { messageShown },
}) => ({
  crsId,
  loading,
  redirect,
  error,
  errorData,
  permsError,
  isLite,
  isHarness,
  device,
  harnessLoading,
  messageShown,
});

const mapDispatchToProps = dispatch => ({
  handleGetAuth: bindActionCreators(getAuth, dispatch),
  handleAddRefreshAuthListener: bindActionCreators(addRefreshAuthListener, dispatch),
  handleResetUserMessages: bindActionCreators(resetUserMessages, dispatch),
  handleHarnessInit: location => dispatch(harnessInit(location)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
