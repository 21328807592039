import React, { Component } from 'react';
import { PageSection, IconCircleExclamation, HarnessExitButton } from 'adc-ui-components';
import { kibanaLog } from '../../helpers/logger';
import getConfig from '../../config';

import PageTitle from '../../components/PageTitle';

const { homeDomain } = getConfig();

class GenericError extends Component {
  componentDidMount() {
    const { isErrorBoundary } = this.props;
    // todo, make this better
    if (!isErrorBoundary) {
      const { error } = this.props;
      kibanaLog('generic_error', error);
    }
  }

  render() {
    const { isLite, isHarness } = this.props;

    return (
      <PageSection className="ui-grey page-section--banner">
        <IconCircleExclamation className="mb6 svg-icon--42" />

        <div className="hgroup">
          <p className="heading4">ERROR</p>
          <PageTitle>Sorry, this isn’t working right now</PageTitle>
          <p className="body1">Please return to My Account Overview while we resolve the issue.</p>
        </div>

        {!isLite && !isHarness && <a className="button button--primary" href={`https://${homeDomain}/#/`}>Back to My Account</a>}
        {!isLite && isHarness && <HarnessExitButton>Back to My Account</HarnessExitButton>}
        {isLite && <a className="button button--primary" href={`https://${homeDomain}/secure/myaccount`}>Sign in to My Account</a>}
      </PageSection>
    );
  }
}

export default GenericError;
