import {
  GET_AUTH,
  GET_AUTH_SUCCESS,
  GET_AUTH_FAILURE,
  SET_AUTH_STATUS,
  SET_FORCE_AUTHENTICATION,
  SET_PERMS_ERROR,
  SET_ACCOUNT_PERMS,
  SET_ACCOUNT_INDEX,
} from '../actions/auth';

import {
  PAYMENT_SETUP,
} from '../helpers/routes';

import getConfig from '../config';
import featureFlags, { flagEnabled } from '../helpers/featureFlags';
import { getCollectionsState, getPaymentSetupState } from '../helpers/payments';

export const INITIAL_STATE = () => {
  const {
    paymentSetup: { defaultContinue },
  } = getConfig();
  const locationInfo = {
    query: window.location.search,
    path: window.location.pathname,
  };

  const state = {
    loading: true,
    ...getCollectionsState(locationInfo),
    ...getPaymentSetupState(locationInfo),
  };

  if (
    // Only do this for /payment-setup without ?crsId
    window.location.pathname === PAYMENT_SETUP
    && !state.crsId
  ) {
    featureFlags.onReady(() => {
      if (
        flagEnabled('consent.enabled')
        || flagEnabled('paymentSetup.enabled')
      ) {
        // If either payment-setup or consent is enabled, allow it.
        return;
      }

      // Redirect to either ?continueUrl / ?fallbackUrl, or the default continue URL from config.
      window.location = state.fallbackUrl || defaultContinue;
    });
  }
  return state;
};

export default function authReducer(state = INITIAL_STATE(), action) {
  switch (action.type) {
    case GET_AUTH:
      return {
        ...state,
        loading: action.showLoading,
        promise: action.payload,
      };
    case SET_ACCOUNT_PERMS:
      return {
        ...state,
        accountPerms: action.payload,
      };
    case GET_AUTH_SUCCESS:
      if (flagEnabled('consent.enabled') && state.crsId) {
        return {
          ...state,
          loading: false,
          error: false,
          crs: action.payload,
          macaroon: false,
        };
      }

      return {
        ...state,
        loading: false,
        error: false,
        macaroon: action.payload,
        ...(flagEnabled('consent.enabled') ? {
          crs: false,
          crsId: false,
        } : {}),
      };
    case GET_AUTH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorData: action.payload,
        macaroon: false,
        ...(flagEnabled('consent.enabled') ? {
          crs: false,
          crsId: false,
        } : {}),
      };
    case SET_AUTH_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SET_FORCE_AUTHENTICATION:
      return {
        ...state,
        redirect: true,
      };
    case SET_ACCOUNT_INDEX:
      return { ...state, accountIndex: action.payload };
    case SET_PERMS_ERROR:
      return {
        ...state,
        permsError: true,
        loading: false,
      };
    default:
      return state;
  }
}
