import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { PageSection } from 'adc-ui-components';
import dateformat from 'dateformat';

import getConfig from '../../config';

import { getIsToday } from '../../helpers/date';
import { getIsXapExit, getIsXapCurrent, getIsXapExitInProgress } from '../../helpers/xap';
import { joinClass } from '../../helpers/component';
import { kibanaLog } from '../../helpers/logger';
import { scrollToInvalid, getErrorFromResponse } from '../../helpers/errors';
import { reviewPayment, submitPayment } from '../../actions/payment';
import { setSubmit, setSuccess, setError } from '../../actions/userMessages';
import { getDefaultInstrumentValue } from '../../helpers/paymentInstrument';

import ErrorMessage from '../../components/ErrorMessage';
import PaymentAmount from '../../components/form-fields/PaymentAmount';
import PaymentDate from '../../components/form-fields/PaymentDate';
import PaymentMethods from '../../components/form-fields/PaymentMethods';
import NextBestActions from '../../components/nbas';
import PageTitle from '../../components/PageTitle';
import InstallmentPlanBanner from '../../components/InstallmentPlanBanner';
import Jump from '../Jump';

import Promotions from './Promotions';

import {
  NEW_PAYMENT_CONFIRMATION,
  NEW_PAYMENT_REVIEW,
  NEW_PAYMENT_BANK,
  NEW_PAYMENT_CARD,
  NEW_PAYMENT,
  METHODS,
} from '../../helpers/routes';
import { getIsDisconnected } from '../../helpers/account';
import { cpcDataToPaymentFormData, getCpcPageType, paymentFlags } from '../../helpers/payments';
import useCpc from '../../hooks/useCpc';
import Card from '../../components/Card';
import { forceLoginLink } from '../../helpers/auth';
import AccountInfoPushdown from './AccountInfoPushdown';

const { homeDomain } = getConfig();

const PaymentForm = ({
  accountNumber,
  autopay,
  bill,
  instruments,
  initialValues,
  history,
  handleReviewSubmit,
  isHarness,
  showSavedPaymentMethods,
  canStoreInstruments,
  showPaymentDate,
  showManageInstruments,
  applePay,
  handlePaymentSubmit,
  errorMessage,
  paymentFormData,
  handleSetPaymentFormData,
  handleSetError,
  handleSetSubmit,
  handleSetSuccess,
  nextBestActions,
  isLite,
  isXapExit,
  disabled,
  submitting,
  showFlexPromo,
  peacockPromo,
  isLtip,
  selectedAccountAlias,
  serviceAddress,
}) => {
  const formErrorRef = useRef(null);
  const cpc = useCpc();

  useEffect(() => {
    if (!errorMessage) return;
    formErrorRef.current && formErrorRef.current.focus();
  }, [errorMessage]);

  const onFormSubmit = async (values, { setErrors, setSubmitting }) => {
    const {
      paymentMethodOption,
      date,
    } = values;

    kibanaLog('payment_form_submit', {
      form: 'NewPayment',
    });

    handleSetSubmit();
    // store payment data in the state and submit in addinstrument form
    // or restore initialValues from state if the user comes back
    handleSetPaymentFormData(values);
    // For lite users, we use the CPC CardOnly mode
    // This handles submitting that subform and getting back its token.
    if (isLite) {
      const cpcPromise = cpc.submitForm();
      try {
        return handleReviewSubmit({
          ...values,
          ...cpcDataToPaymentFormData(await cpcPromise),
        });
      } catch (e) {
        setSubmitting(false);
        handleSetError({ text: e.message });
        return false;
      }
    }

    if (paymentMethodOption === 'Bank') {
      return history.push(NEW_PAYMENT_BANK);
    }

    if (paymentMethodOption === 'PaymentCard') {
      return history.push(NEW_PAYMENT_CARD);
    }

    if (paymentMethodOption === 'Apple Pay') {
      if (!getIsToday(date)) {
        setSubmitting(false);
        const submitErrors = {
          date: 'Apple Pay is only available for payments made today',
        };
        setErrors(submitErrors);
        // focusing field triggers calendar is weird, so just scroll to top
        // by not passing the error object
        scrollToInvalid();
        return false;
      }

      return handleReviewSubmit(values).then(() => (
        handlePaymentSubmit(false).then(() => {
          handleSetSuccess();
          history.push(NEW_PAYMENT_CONFIRMATION);
        }).catch((applePayServerErr) => {
          setSubmitting(false);
          scrollToInvalid();

          if (applePayServerErr.type !== 'cancel') {
            const applePayServerError = getErrorFromResponse(applePayServerErr);

            if (applePayServerError.message) {
              return handleSetError({ text: applePayServerError.message });
            }

            return handleSetError({ showModal: true });
          }
          return false;
        })
      ));
    }

    return handleReviewSubmit(values).then(() => history.push(NEW_PAYMENT_REVIEW));
  };
  const {
    loginForBank,
    showBank,
    showCard,
  } = paymentFlags({ isLite, instruments });
  const cpcPageType = getCpcPageType({ isLite, showBank, showCard });

  return (
    <>
      <AccountInfoPushdown
        accountNumber={accountNumber}
        selectedAccountAlias={selectedAccountAlias}
        serviceAddress={serviceAddress}
      />
      <PageSection>
        <Promotions showFlexPromo={showFlexPromo} peacockPromo={peacockPromo} preload />
        {nextBestActions && <NextBestActions nbaList={nextBestActions} className="move-up12" />}
        <Formik
          // Control whether Formik should reset the form if initialValues changes
          enableReinitialize
          onSubmit={onFormSubmit}
          initialValues={{
            ...initialValues,
            ...paymentFormData,
          }}
        >
          {({
            values,
            setFieldError,
            setFieldValue,
            setTouched,
            touched,
            isValid,
            submitCount,
          }) => {
            const linkErrorMessage = (!isValid && submitCount > 0) ? 'Please fix the form errors to continue' : errorMessage;
            const visuallyHidden = (!isValid && submitCount > 0) ? 'visuallyhidden' : '';
            return (

              <Form noValidate aria-describedby={linkErrorMessage && 'form-error'}>
                <PageTitle className="visuallyhidden">Pay Your Bill</PageTitle>
                {(isXapExit || isLtip) && !isLite && <InstallmentPlanBanner className="mb30" isHarness={isHarness} isLtip={isLtip} />}
                {linkErrorMessage && (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  <div ref={formErrorRef} tabIndex="0" className="hide-focus">
                    <ErrorMessage id="form-error" errorMessage={linkErrorMessage} className={visuallyHidden} />
                  </div>
                )}

                <PaymentAmount
                  bill={bill}
                  setFieldError={setFieldError}
                  setFieldValue={setFieldValue}
                  setFieldTouched={(name, value) => setTouched({ ...touched, [name]: value })}
                  values={values}
                />

                {showPaymentDate && (
                <PaymentDate
                  bill={bill}
                  values={values}
                  setFieldValue={setFieldValue}
                />
                )}
                <div className="payment-section">
                  <div className="hgroup hgroup--inline">
                    <h2>Payment Method</h2>
                    {showManageInstruments && (
                    <Link
                      to={{
                        pathname: METHODS,
                        state: { previousPage: NEW_PAYMENT },
                      }}
                    >
                      Manage Payment Methods
                    </Link>
                    )}
                  </div>
                  {isLite
                    ? (
                      <>
                        <Jump
                          cpcPageType={cpcPageType}
                          cpcPageCssUrl="/cpc/jump.css"
                          shadowCssUrl="/cpc/shadow.css"
                          cpcPageWidth="100%"
                          newPaymentDisplayType="radio"
                        />
                        <Card severity={loginForBank ? 'minty' : 'warning'}>
                          {!loginForBank && 'Only credit/debit cards are accepted at this time.'}
                          {loginForBank && (
                            <>
                              If you want to make a payment with your bank account please
                              {' '}
                              <a href={forceLoginLink(window.location.pathname)}>
                                sign in
                              </a>
                            </>
                          )}
                        </Card>
                      </>
                    )
                    : (
                      <PaymentMethods
                        autopay={autopay}
                        applePay={applePay}
                        instruments={instruments}
                        showSavedPaymentMethods={showSavedPaymentMethods}
                        canStoreInstruments={canStoreInstruments}
                        bill={bill}
                        values={values}
                        isLite={isLite}
                        setFieldValue={setFieldValue}
                      />
                    )
                  }
                </div>

                <div className="action action--right">
                  <div className="action__item">
                    <button
                      className={joinClass('button button--primary', submitting && 'is-loading')}
                      disabled={disabled || submitting}
                      type="submit"
                    >
                      Continue
                    </button>
                  </div>
                  {!isHarness && (
                  <div className="action__item">
                    <a className="button" href={`https://${homeDomain}/`}>Cancel</a>
                  </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </PageSection>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    auth: { macaroon: { lite: isLite, accounts = [] } },
    autopay: {
      autopay,
    },
    account: {
      account,
      loading: accountLoading,
      account: {
        accountNumber,
        serviceAddress = {},
        status,
      } = {},
    },
    bill: { bill = {}, loading: billLoading },
    applePay: { applePay },
    payment: { submitting },
    instruments: { instruments, loading: instrumentsLoading },
    harness: { isHarness },
    userMessages: {
      error: errorMessage,
      showModal,
    },
  } = state;

  let paymentAmountOption = (
    bill.summary && bill.summary.balanceDue && bill.summary.balanceDue.toString()
  );

  if (!bill.summary || (bill.summary && bill.summary.balanceDue <= 0)) {
    paymentAmountOption = 'custom';
  }

  const isDisconnected = getIsDisconnected(status);
  const isXapExit = getIsXapExit(account);
  const isXapCurrent = getIsXapCurrent(account);
  const isXapInProgress = getIsXapExitInProgress(account);
  const isXapCustomer = isXapExit || isXapCurrent || isXapInProgress;
  const isLtipEligible = account?.xap.ltip === 'ELIGIBLE';
  const isServicePause = account?.xap.servicePause === 'ELIGIBLE';
  return {
    accountNumber,
    autopay,
    instruments,
    bill,
    applePay,
    disabled: accountLoading || billLoading || instrumentsLoading,
    submitting,
    isHarness,
    isLite,
    isXapExit: isXapExit || isLtipEligible,
    isLtip: isLtipEligible && isServicePause && !isXapCustomer,
    showSavedPaymentMethods: !isLite && !isDisconnected,
    canStoreInstruments: !isLite && !isDisconnected,
    showPaymentDate: !isLite && !isDisconnected && !isXapCustomer && !accountLoading,
    showManageInstruments: (
      !isHarness
      && !isLite
      && !isDisconnected
    ),
    errorMessage: !showModal && errorMessage,
    initialValues: {
      customAmount: '',
      paymentAmountOption,
      date: dateformat(new Date(), 'm/d/yyyy'), // don't make this UTC, should always be today local
      paymentMethodOption: (isDisconnected || isLite)
        ? 'PaymentCard'
        : getDefaultInstrumentValue(instruments, autopay),
    },
    selectedAccountAlias: accounts.find(acct => acct.isSelectedAccount)?.alias,
    serviceAddress,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleReviewSubmit: reviewPayment,
  handlePaymentSubmit: submitPayment,
  handleSetSubmit: setSubmit,
  handleSetSuccess: setSuccess,
  handleSetError: setError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
