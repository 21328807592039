import React, { Component, createRef } from 'react';
import { FormControl, FormBoolean, LoadingCard } from 'adc-ui-components';
import { Field } from 'formik';
import dateFormat from 'dateformat';
import { connect } from 'react-redux';

import BooleanField from '../form-elements/BooleanField';
import PaymentAmountField from './PaymentAmountField';
import Card from '../Card';
import {
  normalizeDollarAmount,
  vPaymentAmount,
  vDollarAmount,
  cleanDollarAmount,
} from '../../helpers/validation/amount';
import { getPaymentMessage } from '../../helpers/paymentMessage';
import p2pAmountMessage from '../p2p/p2pAmountMessage';
import { bindListeners, joinClass } from '../../helpers/component';
import { formatCurrency } from '../../helpers/formatText';
import { storeSelection, completeSelection } from '../../helpers/selection';
import { getIsXapCurrent } from '../../helpers/xap';

class PaymentAmount extends Component {
  constructor() {
    super();
    bindListeners(this, [
      'updateAmountWarning',
      'storeSelection',
      'updateViewport',
    ]);
    this.formErrorRef = createRef(null);
    this.amountWarningRef = createRef(null);
    this.state = {
      showError: false,
      isViewportWiderThan768: window.matchMedia('(min-width: 768px)').matches,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateViewport, false);
    // set messaging on mount
    return this.updateAmountWarning();
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props;
    if (
      // We may also depend on `date` for P2P
      prevProps.values.date !== values.date
      || prevProps.values.paymentAmountOption !== values.paymentAmountOption
    ) {
      this.updateAmountWarning();
    }
    const { error } = this.state;

    if (!error) return;
    this.formErrorRef.current && this.formErrorRef.current.focus();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewport, false);
  }

  setError(name, message) {
    this.setState({
      showError: name,
      error: message,
      amountWarning: false,
    });
  }

  setAmountWarning(severity, message) {
    this.amountWarningRef.current && this.amountWarningRef.current.focus();
    this.setState({
      showError: false,
      amountWarning: {
        severity,
        message,
      },
    });
  }

  updateViewport() {
    this.setState(state => ({
      ...state,
      isViewportWiderThan768: window.matchMedia('(min-width: 768px)').matches,
    }));
  }

  updateAmountWarning() {
    const {
      account,
      status,
      bill,
      values,
      values: {
        customAmount,
        paymentAmountOption,
        date,
      },
      setFieldTouched,
      setFieldError,
      isXapCurrent,
    } = this.props;

    if (!customAmount || !paymentAmountOption) {
      this.clearErrors();
      return;
    }
    const paymentInvalid = (
      status === 'DISCONNECTED'
      && paymentAmountOption === 'custom'
      && vPaymentAmount(bill, customAmount)
    );
    setFieldError('customAmount', paymentInvalid);
    setFieldTouched('customAmount', !!paymentInvalid);

    const payment = {
      date,
      // Prefer global parseFloat. IE doesn't support Number.parseFloat
      amount: parseFloat(cleanDollarAmount(customAmount)),
    };
    const paymentError = p2pAmountMessage({
      base: getPaymentMessage({
        bill,
        payment,
        status,
        type: 'form',
        isXapCurrent,
      }),
      account,
      bill,
      values,
    });
    if (paymentError && paymentError.message) {
      this.setAmountWarning(paymentError.severity, paymentError.message);
    } else {
      this.clearErrors();
    }
  }

  clearErrors() {
    this.setState({ showError: false, amountWarning: false });
  }

  storeSelection(event) {
    return storeSelection(this, event, /[$.\s]/g);
  }

  render() {
    const {
      status,
      bill: { summary = {} },
      loading,
      values: { paymentAmountOption },
      setFieldValue,
    } = this.props;
    const vCustomAmount = (value) => {
      if (paymentAmountOption === 'custom') {
        const amountError = vDollarAmount(value);
        if (status === 'DISCONNECTED') {
          const paymentError = vPaymentAmount({ summary }, value);
          return paymentError || amountError;
        }
        return amountError;
      }

      return undefined;
    };
    const setFieldError = (showError, error) => {
      this.setState({
        showError,
        error,
        amountWarning: false,
      });
    };
    const normalizeAmount = (value) => {
      if (/[^$0-9.]/.test(value)) {
        setFieldError('customAmount', 'Please enter numeric characters only');
      }

      const result = normalizeDollarAmount(value);
      completeSelection(this, 'customAmount', value, result);
      return result;
    };

    const {
      showError,
      error,
      amountWarning,
      formErrorRef,
      amountWarningRef,
      isViewportWiderThan768,
    } = this.state;

    const shouldShowCustomInput = isViewportWiderThan768 || paymentAmountOption === 'custom';

    return (
      <div className="payment-section">
        <h2>Payment Amount</h2>

        <div className="card-group">
          {loading && (
            <div className="card-group__item">
              <LoadingCard />
            </div>
          )}
          {!!summary.pastDueBalanceRemaining && (
            <div className="card-group__item">
              <div className="card card--action-right-at-768">
                <div className="card__content">
                  <FormBoolean
                    label="Past due balance"
                    inputId="paymentAmountOptionPastDueBalance"
                    labelClass="body2"
                  >
                    <Field
                      id="paymentAmountOptionPastDueBalance"
                      name="paymentAmountOption"
                      component={BooleanField}
                      type="radio"
                      value={`${summary.pastDueBalanceRemaining}`}
                    />
                  </FormBoolean>
                </div>
                <div className="card__action ml40 pt0">
                  <span className="heading2 color--danger">
                    { formatCurrency(summary.pastDueBalanceRemaining) }
                  </span>
                </div>
              </div>
            </div>
          )}

          {summary.balanceDue !== undefined && (
            <div className="card-group__item">
              <div className="card card--action-right-at-768">
                <div className="card__content">
                  {summary.balanceDue > 0.00 && (
                    <FormBoolean
                      label={`Current balance due ${dateFormat(summary.dueDateInMillis, 'mmm d, yyyy', true)}`}
                      inputId="paymentAmountOptionCurrentBalance"
                      labelClass="body2"
                    >
                      <Field
                        id="paymentAmountOptionCurrentBalance"
                        name="paymentAmountOption"
                        component={BooleanField}
                        type="radio"
                        value={`${summary.balanceDue}`}
                      />
                    </FormBoolean>
                  )}
                  {summary.balanceDue === 0.00 && (<h3 className="label">No balance due</h3>)}
                  {summary.balanceDue < 0.00 && (<h3 className="label">You have a credit balance on your account</h3>)}
                </div>

                <div className="card__action pt0">
                  {summary.balanceDue >= 0.00 && (
                    <span className="heading2 ml40">
                      {formatCurrency(summary.balanceDue)}
                    </span>
                  )}

                  {summary.balanceDue < 0.00 && (
                    <span className="heading2 color--green-forest">
                      {`+${formatCurrency(summary.balanceDue * -1)}`}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="card-group__item">
            <div className="card card--action-right-at-768">
              <div className="card__content">
                <FormBoolean
                  label="Pay another amount"
                  inputId="paymentAmountOptionUserDefined"
                  labelClass="body2"
                >
                  <Field
                    id="paymentAmountOptionUserDefined"
                    name="paymentAmountOption"
                    component={BooleanField}
                    type="radio"
                    value="custom"
                    onChange={({ target: { name, value, checked } }) => {
                      if (checked) {
                        document.getElementById('customAmount').focus();
                        setFieldValue(name, value);
                      }
                    }}
                  />
                </FormBoolean>
              </div>
              <div className={joinClass('card__action', !shouldShowCustomInput && 'hidden')}>
                <FormControl>
                  <label htmlFor="customAmount" className="visuallyhidden">Payment amount</label>
                  <PaymentAmountField
                    model="customAmount"
                    aria-required={paymentAmountOption === 'custom'}
                    aria-describedby="customAmountError"
                    tabIndex={paymentAmountOption === 'custom' ? '' : '-1'}
                    validate={vCustomAmount}
                    onBlur={this.updateAmountWarning}
                    onFocus={() => {
                      const radio = document.getElementById('paymentAmountOptionUserDefined');
                      if (!radio.checked) radio.click();
                    }}
                    onChange={(e) => {
                      setFieldValue('customAmount', normalizeAmount(e.target.value));
                    }}
                  />
                  {showError === 'customAmount' && (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  <span role="alert" ref={formErrorRef} tabIndex="0" id="customAmountError" className="form-control__error hide-focus">
                    {error}
                  </span>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        {paymentAmountOption === 'custom' && amountWarning && (
          <Card severity={amountWarning.severity}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <p className="form-control__warning hide-focus" tabIndex="0" role="alert" ref={amountWarningRef}>
              {amountWarning.message}
            </p>
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  bill: { loading },
  account: { account },
}) => ({
  loading,
  account,
  status: account?.status,
  isXapCurrent: getIsXapCurrent(account),
});

export default connect(mapStateToProps)(PaymentAmount);
