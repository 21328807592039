import React from 'react';
import { FormBoolean } from 'adc-ui-components';
import { Field } from 'formik';

import Card from '../Card';
import ApplePayMethod from './ApplePayMethod';
import SavedPaymentMethods from './SavedPaymentMethods';
import BankPaymentMethod from './BankPaymentMethod';
import CardPaymentMethod from './CardPaymentMethod';
import BooleanField from '../form-elements/BooleanField';
import { forceLoginLink } from '../../helpers/auth';
import { paymentFlags } from '../../helpers/payments';


const PaymentMethods = (props) => {
  const {
    isLite,
    autopay,
    instruments,
    showSavedPaymentMethods = true,
    canStoreInstruments = true,
    values,
    values: { paymentMethodOption },
    error,
    applePay,
    nestFields: nested,
    forceBillingAddress,
    className = '',
    setFieldValue,
    required = true,
  } = props;

  const {
    loginForBank,
    showBank,
    showCard,
    applePayAvailable,
  } = paymentFlags({ isLite, instruments });

  const showApplePay = (
    applePay // apple pay data in store
    && applePayAvailable // flag, browser allows it, and not in lite
    && !instruments?.cardblockStatus // user not blocked from credit cards
    && !!instruments?.options?.find(option => option.type === 'ApplePay')
    // payment service supports apple pay
  );

  return (
    <>
      {error && <p className="form-control__error">Please select a payment method</p>}

      <div className={`card-group ${className}`}>
        {showSavedPaymentMethods
          && <SavedPaymentMethods autopay={autopay} instruments={instruments} />}

        {showApplePay && <ApplePayMethod autopay={autopay} />}
        {!isLite && showBank && (
          <div className="card-group__item">
            <div className="card card--form-control">
              <FormBoolean
                inputId="paymentMethodBank"
                label={canStoreInstruments ? 'New bank account' : 'Bank account'}
                labelClass="body2"
              >
                <Field
                  id="paymentMethodBank"
                  name="paymentMethodOption"
                  type="radio"
                  component={BooleanField}
                  value="Bank"
                />
              </FormBoolean>
            </div>
          </div>
        )}
        {nested && paymentMethodOption === 'Bank' && (
          <div className="card-group__item">
            <BankPaymentMethod {...{
              nested,
              values,
              forceBillingAddress,
              setFieldValue,
              required,
            }}
            />
          </div>
        )}
        {!isLite && showCard && (
          <div className="card-group__item">
            <div className="card card--form-control">
              <FormBoolean
                inputId="paymentMethodCC"
                label={canStoreInstruments ? 'New credit/debit card' : 'Credit/debit card'}
                labelClass="body2"
              >
                <Field
                  id="paymentMethodCC"
                  name="paymentMethodOption"
                  type="radio"
                  component={BooleanField}
                  value="PaymentCard"
                  aria-required
                />
              </FormBoolean>
            </div>
          </div>
        )}
        {(isLite || (nested && paymentMethodOption === 'PaymentCard')) && (
          <CardPaymentMethod {...{
            nested,
            values,
            forceBillingAddress,
            setFieldValue,
            required,
          }}
          />
        )}
      </div>
      {!showBank && showCard && (
        <Card severity={loginForBank ? 'minty' : 'warning'}>
          {!loginForBank && 'Only credit/debit cards are accepted at this time.'}
          {loginForBank && (
            <>
              If you want to make a payment with your bank account please
              {' '}
              <a href={forceLoginLink(window.location.pathname)}>
                sign in
              </a>
            </>
          )}
        </Card>
      )}
      {showBank && !showCard && (
        <Card severity="warning">
          Only bank accounts are accepted at this time.
        </Card>
      )}
    </>
  );
};

export default PaymentMethods;
